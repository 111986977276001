<template>
  <div>
    <CButton
      @click="show"
    >SHOW
    </CButton>
    <CCard>
      <CCardHeader>
        <div class="card-header-actions">
          <div style="cursor:pointer" @click="$store.state.showMemberList = !$store.state.showMemberList">
            <CIcon :name="`cil-chevron-${$store.state.showMemberList ? 'bottom' : 'top'}`"/>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="3">
            <my-logo/>
          </CCol>
          <CCol lg="9">
            <create-job
              v-bind:job_id.sync="id"
              v-bind:title.sync="title"
              v-bind:job_detail.sync="job_detail"
              v-bind:start_date.sync="start_date"
              v-bind:end_date.sync="end_date"
              v-bind:finish_date.sync="finish_date"
              v-bind:price.sync="price"
              v-bind:status="status"
              v-on:update:status="status = $event.target.value"
              v-bind:worker_id.sync="worker_id"
              v-bind:category_id="category_id"
              v-on:update:category_id="category_id = $event.target.value"
              v-bind:client_id.sync="client_id"
              v-bind:is_disabled="false"
              v-on:update:addLinks="addLinks($event)"
              v-on:update:links="item.links = $event"
              v-bind:links="links"
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="col-lg-2">
            <CButton
              style="width:100%"
              variant="outline"
              @click="moveToListJobs"
              size="sm"
              color="warning"
            >キャンセル
            </CButton>
          </CCol>
          <CCol class="col-lg-2 offset-lg-8">
            <CButton
              style="width:100%"
              variant="outline"
              @click="updateJob"
              size="sm"
              color="primary"
            >送信
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import CreateJob from "@/views/v1/components/CreateJob"
import MyLogo from "@/views/v1/components/MyLogo"

export default {
  name: "CreateJob",
  mixins: [Comm],/* ここでミックスインを追加 */
  props: {
    _item: {
      id: [String, Number],
      family_id: [String, Number],
      title: String,
      start_date: String,
      end_date: String,
      finish_date: String,
      client_id: [String, Number],
      price: [String, Number],
      status: [String, Number],
      job_detail: String,
      worker_id: [String, Number],
      category_id: [String, Number],
      links: Array
    },
  },
  mounted() {
  },
  components: {
    "create-job": CreateJob,
    "my-logo": MyLogo,
  },
  computed: {
    id: {
      get() {
        return this._item.id
      },
    },
    family_id: {
      get() {
        return this._item.family_id
      },
    },
    title: {
      set: function (value) {
        this.item.title = value
      },
      get() {
        if (this.item.title === undefined) {
          return this._item.title
        } else {
          return this.item.title
        }
      },
    },
    job_detail: {
      set: function (value) {
        this.item.job_detail = value
      },
      get() {
        if (this.item.job_detail === undefined) {
          return this._item.job_detail
        } else {
          return this.item.job_detail
        }
      },
    },
    start_date: {
      set: function (value) {
        this.item.start_date = value
      },
      get() {
        if (this.item.start_date === undefined) {
          return this._item.start_date
        } else {
          return this.item.start_date
        }
      },
    },
    end_date: {
      set: function (value) {
        this.item.end_date = value
      },
      get() {
        if (this.item.end_date === undefined) {
          return this._item.end_date
        } else {
          return this.item.end_date
        }
      },
    },
    finish_date: {
      set: function (value) {
        this.item.finish_date = value
      },
      get() {
        if (this.item.finish_date === undefined) {
          return this._item.finish_date
        } else {
          return this.item.finish_date
        }
      },
    },
    client_id: {
      set: function (value) {
        this.item.client_id = value
      },
      get() {
        if (this.item.client_id === undefined) {
          return this._item.client_id
        } else {
          return this.item.client_id
        }
      },
    },
    price: {
      set: function (value) {
        this.item.price = value
      },
      get() {
        if (this.item.price === undefined) {
          return this._item.price
        } else {
          return this.item.price
        }
      },
    },
    status: {
      set: function (value) {
        this.item.status = value
      },
      get() {
        if (this.item.status === undefined) {
          return this._item.status
        } else {
          return this.item.status
        }
      },
    },
    worker_id: {
      set: function (value) {
        this.item.worker_id = value
      },
      get() {
        if (this.item.worker_id === undefined) {
          return this._item.worker_id
        } else {
          return this.item.worker_id
        }
      },
    },
    category_id: {
      set: function (value) {
        this.item.category_id = value
      },
      get() {
        if (this.item.category_id === undefined) {
          return this._item.category_id
        } else {
          return this.item.category_id
        }
      },
    },
    links: {
      set: function (value) {
        this.item.links = value
      },
      get() {
        if (this.item.links === undefined) {
          if(this._item.links.length === 0){
            return [{title: "参考ページ", href: ""}]
          }else{
            return this._item.links
          }
        } else {
          return this.item.links
        }
      },
    },
  },
  data() {
    return {
      item: {
        title: undefined,
        start_date: undefined,
        end_date: undefined,
        finish_date: undefined,
        client_id: 1,
        price: undefined,
        status: undefined,
        job_detail: undefined,
        worker_id: undefined,
        category_id: undefined,
        links: undefined
      }
    }
  },
  methods: {
    addLinks(e){
      this.item.links = this.links
      if(this.item.links.length -1 === e){
        this.item.links.push({title: "", href: ""})
      }else{
        this.item.links[e]["link_type"] = -1
      }
    },
    show() {
      console.log("props ", this.$props)
      console.log("data ", this.item)
    },
    showEvent(e) {
      console.log(e)
    },
    updateEvent(e) {
      console.log(e)
    },
    moveToListJobs() {
      this.$router.push({name: 'ListJobs'})
    },
    async updateJob() {
      this.links.map((link, index) => {
        if(link["href"] === "" || link["title"] === ""){
          this.links.splice(index, 1)
        }
      })
      // console.log({
      //   id: this.id,
      //   family_id: this.family_id,
      //   title: this.title,
      //   start_date: this.start_date,
      //   end_date: this.end_date,
      //   finish_date: this.finish_date,
      //   client_id: 1,
      //   price: this.price,
      //   status: this.status,
      //   job_detail: this.job_detail,
      //   worker_id: this.worker_id,
      //   category_id: this.category_id,
      //   links: this.links
      // })
      const response = await this._updateItem(
        {
          id: this.id,
          title: this.title,
          start_date: this.start_date,
          end_date: this.end_date,
          finish_date: this.finish_date,
          client_id: 1,
          price: this.price,
          status: this.status,
          job_detail: this.job_detail,
          worker_id: this.worker_id,
          category_id: this.category_id,
          links: this.links
        })
      await this.$router.push({name: 'ListJobs'})
    }
  }
}
</script>

<style scoped>
</style>
